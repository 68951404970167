
body{
    overflow-x: hidden;

    .content-home {
    min-height: 100vh;
    padding: 30px 0px 20px 10px;
    background-color: #eee;
}
}
