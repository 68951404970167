$green: #86BB71;
$blue: #94C2ED;
$orange: #E38968;
$gray: #92959E;
.boxConversation {
    position: relative;
    div.appBar {
        height: 50px;
        width: 100%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    ul {
        padding: 20px;
        height: 770px;
        li {
            padding-bottom: 20px;
            list-style: none;
        }
    }
    .chat-history {
        padding: 30px 30px 20px;
        border-bottom: 2px solid white;
        overflow: auto;
        height: 570px;
        width: 100%;
        .message-data {
            margin-bottom: 15px;
        }
        .message-data-time {
            color: lighten($gray, 8%);
            padding-left: 6px;
        }
        .message {
            color: white;
            padding: 18px 20px;
            line-height: 26px;
            font-size: 16px;
            border-radius: 7px;
            margin-bottom: 30px;
            max-width: 90%;
            position: relative;
            &:after {
                bottom: 100%;
                left: 7%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-bottom-color: $green;
                border-width: 10px;
                margin-left: -10px;
            }
        }
        .my-message {
            background: $green;
        }
        .other-message {
            background: $blue;
            &:after {
                border-bottom-color: $blue;
                left: 93%;
            }
        }
    }
    .online,
    .offline,
    .me {
        margin-right: 3px;
        font-size: 10px;
    }
    .online {
        color: $green;
    }
    .offline {
        color: $orange;
    }
    .me {
        color: $blue;
    }
    .align-left {
        text-align: left;
    }
    .align-right {
        text-align: right;
    }
    .float-right {
        float: right;
    }
    .clearfix:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }
}