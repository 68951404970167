.menuLeft {
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;



    .itemSidebar {
        margin-right: 10px;
        transition: all .5s;
    }
    .border_radius_right {
        border-radius: 0px 30px 30px 0px;
    }
}