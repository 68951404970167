@import './Common/default.scss';
.content {
    width: 100%;
}

.contentNight {
    width: 100%;
}

.logoApp-2x {
    width: 80px;
}

.App {
    text-align: center;
}

.App-logo {
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.pt-30 {
    padding-top: 30px;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.my-node-enter {
    opacity: 0;
}

.my-node-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.my-node-exit {
    opacity: 1;
}

.my-node-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.content,
.contentNight {
    min-height: 100vh;
}