.splashScreen {
    background-image: url('../../Assets/Images/jpg/splash2.jpg');
    background-size: 150%;
    height: 100vh !important;
    background-repeat: no-repeat;
    background-position: left;
    animation: slide 6s ease;
    transform: translate3d(0,0,0);

    h1 {
        font-family: 'Baloo Da 2' , cursive;
        font-size: 70px;
        color: #fff;
        text-align: center;
        padding-top: 5%;
      }
      
      @keyframes delay {
        0% {opacity:0;}
        100% {opacity:1;}
      }
      
      @keyframes drift {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(-360deg); }
      }

      @keyframes slide {
        0%   { background-position: left; }
        50%   { background-position: right; }
        100% { background-position: left; }
      }
      
      .wave {
        opacity: 0.9;
        position: absolute;
        top: 3%;
        left: 10%;
        background-color: rgba($color: #F9A826, $alpha: 0.8);
        width: 400%;
        height: 400%;
        border-radius: 33%;
        animation: drift 8000ms infinite linear;
      }

      .wave1 {
        opacity: 0.9;
        position: absolute;
        top: 3%;
        left: 20%;
        background-color: rgba($color: #a19684, $alpha: 0.6);
        width: 300%;
        height: 300%;
        border-radius: 33%;
        animation: drift 7000ms infinite linear;
      }
      .wave2 {
        opacity: 0.9;
        position: absolute;
        top: 3%;
        left: 40%;
        background-color: rgba($color: #fff, $alpha: 0.6);
        width: 200%;
        height: 200%;
        border-radius: 33%;
        animation: drift 5500ms infinite linear;
      }
      &:before {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100vh !important;
        background: rgba($color: #F9A826, $alpha: 0.55);
      }
      
      &.box {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        > div {
            z-index: 2;
        }
      }
}